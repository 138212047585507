import linkedin from 'images/linkedin.svg';
import twitter from 'images/twitter.svg';
import facebook from 'images/facebook.svg';

export const SOCIAL_NETWORKS = [
  {
    icon: linkedin,
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/company/softteco',
  },
  {
    icon: twitter,
    label: 'Twitter',
    link: 'https://twitter.com/softteco?lang=en',
  },
  {
    icon: facebook,
    label: 'Facebook',
    link: 'https://www.facebook.com/softteco/',
  },
];

export const EMAIL = 'contact@softteco.com';
