import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { validateEmail, validatePhone } from 'components/FormFields/validation';

import Modal from 'components/Modal';
import Input from 'components/FormFields/Input';
import { SecondaryButton } from 'components/Button';
import Animated from 'components/Animation/Animated';

import {
  EMAIL_FIELD,
  PHONE_FIELD,
  INITIAL_FIELDS_STATE,
  SESSION_STORAGE_ID,
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  SUCCESS_MESSAGE,
  TIME_TO_SHOW_MESSAGES,
  TIME_TO_SHOW_MODAL_FORM,
} from './constants';

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 20px 29px;
  background: linear-gradient(
      112.31deg,
      ${({ theme }) => theme.colors.gradient2} 0%,
      ${({ theme }) => theme.colors.gradient} 99.23%
    ),
    #266bf2;
  border-radius: 12px;

  > div {
    width: auto;
  }

  ${FormFooter} {
    width: 100%;
  }

  input:not([type='submit']) {
    display: block;
    width: 266px;
    padding: 9px 10px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.2;

    + span {
      top: 14px;
      right: 1px;
      width: 85px;
      font-size: 10px;
      line-height: 1.1;

      ::before {
        margin-right: 5px;
      }
    }
  }

  ${({ invalidProps }) =>
    invalidProps.map(
      (prop) => `
  input([name='${prop}']) {
    padding-right: 85px;
  }
  `,
    )}

  @media screen and ${({ theme }) => theme.devices.sm} {
    padding-left: 53px;
    padding-right: 53px;
  }
`;

const FormTitle = styled.p`
  margin-bottom: 22px;
  color: ${({ theme }) => theme.colors.lighter};
  font-size: 14px;
  line-height: 1.7;
  text-align: center;
`;

const Separator = styled.span`
  display: flex;
  align-items: center;
  margin: 9px auto;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.lighter};

  ::before,
  ::after {
    content: '';
    display: block;
    width: 74px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lighter};
  }

  ::before {
    margin-right: 12px;
  }

  ::after {
    margin-left: 12px;
  }
`;

const SuccessMessage = styled.span`
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.lighter};
`;

const ErrorMessage = styled.span`
  position: absolute;
  top: 100%;
  display: inline-block;
  width: 100%;
  margin-top: 3px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.errorLight};
`;

const Submit = styled.input`
  ${SecondaryButton}

  width: 216px;
  padding: 13px 38px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 0.86;
  letter-spacing: 1.33333px;
`;

export const Banner = () => {
  const [formFields, setFromFields] = useState({
    ...INITIAL_FIELDS_STATE,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(null);
  const [isDataSending, setDataSending] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formFields[EMAIL_FIELD] && !formFields[PHONE_FIELD]) {
      setErrorMsg(WARNING_MESSAGE);
      return;
    } else {
      setErrorMsg('');
    }

    setDataSending(true);

    const params = {
      from_name: 'Contacts from banner',
      from_email: formFields[EMAIL_FIELD] || '',
      message: 'Phone: ' + (formFields[PHONE_FIELD] || '-'),
    };

    try {
      fetch('/mail/', {
        method: 'POST',
        body: Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&'),
      })
        .then((res) => res.json())
        .then((result) => {
          setIsEmailSent(result.success);
        });
    } catch (e) {
      setIsEmailSent(false);
    }
  };

  const updateFormField = (key, value) =>
    setFromFields({ ...formFields, [key]: value });

  const handleInvalidFields = (status, property) => {
    if (status && !invalidFields.includes(property)) {
      setInvalidFields([...invalidFields, property]);
    }

    if (!status && invalidFields.includes(property)) {
      setInvalidFields(invalidFields.filter((item) => item !== property));
    }
  };

  const toggleModal = (status) => {
    if (!status) {
      sessionStorage.setItem(SESSION_STORAGE_ID, false);
    }

    setIsOpen(status);
  };

  useEffect(() => {
    if (isEmailSent !== null) {
      if (isEmailSent) {
        setIsFormSubmitted(true);
        setFromFields({ ...INITIAL_FIELDS_STATE });
      } else {
        setErrorMsg(ERROR_MESSAGE);
      }

      setDataSending(false);
      setIsEmailSent(null);
    }
  }, [isEmailSent]);

  useEffect(() => {
    if (isFormSubmitted) {
      setTimeout(() => {
        if (isFormSubmitted) {
          toggleModal(false);
        }
      }, TIME_TO_SHOW_MESSAGES);
    }
  }, [isFormSubmitted]);

  const formStatus = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ID));
  useEffect(() => {
    if (formStatus === null || formStatus) {
      setTimeout(() => {
        toggleModal(true);

        if (!formStatus) sessionStorage.setItem(SESSION_STORAGE_ID, true);
      }, TIME_TO_SHOW_MODAL_FORM);
    }
  }, [formStatus]);

  return (
    <Modal
      isVisible={isOpen}
      onClose={() => toggleModal(false)}
      showCloseBtn
      styles={{
        width: '100%',
        maxWidth: 507,
        padding: 0,
        background: 'transparent',
        overflowY: 'visible',
      }}
    >
      <FormWrapper onSubmit={handleSubmit} invalidProps={invalidFields}>
        {isFormSubmitted ? (
          <SuccessMessage>{SUCCESS_MESSAGE}</SuccessMessage>
        ) : (
          <>
            <FormTitle>
              If you’d like to learn more about our services, leave your contact
              information and we’ll promptly get in touch!
            </FormTitle>
            <Input
              value={formFields[EMAIL_FIELD]}
              onChange={(e) => updateFormField(EMAIL_FIELD, e.target.value)}
              placeholder="E-mail"
              validationOnBlur={[validateEmail]}
              handleDisableSubmit={handleInvalidFields}
              name={EMAIL_FIELD}
              disabled={isDataSending}
            />
            <Separator>or</Separator>
            <Input
              value={formFields[PHONE_FIELD]}
              onChange={(e) => updateFormField(PHONE_FIELD, e.target.value)}
              placeholder="Tel"
              validationOnBlur={[validatePhone]}
              handleDisableSubmit={handleInvalidFields}
              name={PHONE_FIELD}
              disabled={isDataSending}
            />
            <FormFooter>
              <Submit
                type="submit"
                value={isDataSending ? 'Sending...' : 'Send'}
                disabled={invalidFields.length || isDataSending}
              />
              <Animated isVisible={!!errorMsg}>
                <ErrorMessage>{errorMsg}</ErrorMessage>
              </Animated>
            </FormFooter>
          </>
        )}
      </FormWrapper>
    </Modal>
  );
};

export default Banner;
