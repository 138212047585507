export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const status = !(email && !re.test(email));

  return { status, message: status ? '' : 'Wrong email' };
};

export const validatePhone = (phone) => {
  let re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const status = !(phone && !re.test(phone));

  return { status, message: status ? '' : 'Wrong phone' };
};
