import React, { useState } from 'react';
import styled from 'styled-components';

import playIcon from 'images/play.svg';

import { Container as VideoContainer } from 'containers/Container';

import { reset } from 'components/Button';
import Modal from 'components/Modal';

import {
  VIDEO,
  SECTION_TITLE,
  VIDEO_MAX_WIDTH,
  VIDEO_ASPECT_RATIO,
} from './constants';

const VideoWrapper = styled.div`
  display: none;
  padding: 25px 0;
  background-image: linear-gradient(
    315deg,
    ${({ theme }) => theme.colors.gradient} 0%,
    ${({ theme }) => theme.colors.gradient2} 100%
  );

  @media screen and ${({ theme }) => theme.devices.lg} {
    display: flex;
  }
`;

const Trigger = styled.button`
  ${reset}

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: bold;
  font-size: 18px;
  line-height: 2.22;
  text-align: center;
  color: ${({ theme }) => theme.colors.lighter};

  img {
    height: auto;
    width: 50px;
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

export const Video = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalSize, setModalSize] = useState({
    width: window.innerWidth * 0.9,
    height: window.innerWidth * 0.9 * VIDEO_ASPECT_RATIO,
  });

  const openVideo = () => {
    if (modalSize.width > VIDEO_MAX_WIDTH) {
      setModalSize({
        width: VIDEO_MAX_WIDTH,
        height: VIDEO_MAX_WIDTH * VIDEO_ASPECT_RATIO,
      });
    }

    setIsOpen(true);
  };

  return (
    <VideoWrapper>
      <VideoContainer>
        <Trigger className="button" onClick={openVideo}>
          <img src={playIcon} alt={SECTION_TITLE} />
          <span>{SECTION_TITLE}</span>
        </Trigger>
        <Modal
          isVisible={isOpen}
          onClose={() => setIsOpen(false)}
          styles={{
            width: modalSize.width,
            padding: '10px 10px 0',
          }}
        >
          <iframe
            title={SECTION_TITLE}
            src={VIDEO}
            width={modalSize.width - 20}
            height={modalSize.height - 10}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
      </VideoContainer>
    </VideoWrapper>
  );
};

export default Video;
