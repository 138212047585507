import { css } from 'styled-components';

export const reset = css`
  padding: 0;
  text-decoration: none;
  color: inhiret;
  background-image: none;
  background-color: transparent;
  border: none;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const common = css`
  ${reset}

  font-weight: bold;
  line-height: 1;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 40px;

  :disabled {
    background: rgba(${({ theme }) => theme.colors.disabledRGB}, 0.4);
    cursor: not-allowed;

    :hover,
    :focus {
      background: rgba(${({ theme }) => theme.colors.disabledRGB}, 0.4);
    }
  }
`;

export const Button = css`
  ${common}

  padding: 10px 50px;
  color: ${({ theme }) => theme.colors.lighter};
  background: ${({ theme }) => theme.colors.primary};

  :hover,
  :focus {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;

export const SecondaryButton = css`
  ${common}

  padding: 10px 20px;
  color: ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.lighter};

  :hover,
  :focus {
    background: rgba(${({ theme }) => theme.colors.lighterRGB}, 0.7);
  }
`;

export default Button;
