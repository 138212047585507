import { createGlobalStyle, css } from 'styled-components';

import InterRegular from 'fonts/Inter-Regular.ttf';
import InterMedium from 'fonts/Inter-Medium.ttf';
import InterSemiBold from 'fonts/Inter-SemiBold.ttf';
import InterBold from 'fonts/Inter-Bold.ttf';
import InterExtraBold from 'fonts/Inter-ExtraBold.ttf';

export const Fonts = css`
  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-style: normal;
    src: url('${InterRegular}') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-style: normal;
    src: url('${InterMedium}') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    src: url('${InterSemiBold}') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: bold;
    font-style: normal;
    src: url('${InterBold}') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 800;
    font-style: normal;
    src: url('${InterExtraBold}') format('truetype');
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${Fonts}

  body {
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: 14px;
    line-height: 1.79;

    @media screen and ${({ theme }) => theme.devices.sm} {
      font-size: 18px;
      line-height: 1.67;
    }
  }
`;

export default GlobalStyle;
