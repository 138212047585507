import samsungLogo from 'images/customers/samsung.svg';
import samsungLogoLg from 'images/customers/samsung-lg.svg';
import samsungLogoColor from 'images/customers/color-samsung.svg';

import evernoteLogo from 'images/customers/evernote.svg';
import evernoteLogoLg from 'images/customers/evernote-lg.svg';
import evernoteLogoColor from 'images/customers/color-evernote.svg';

import theWorldBankLogo from 'images/customers/the-world-bank.svg';
import theWorldBankLogoLg from 'images/customers/the-world-bank-lg.svg';
import theWorldBankLogoColor from 'images/customers/color-the-world-bank.svg';

import barnesAndNobelLogo from 'images/customers/barnes-and-noble.svg';
import barnesAndNobelLogoLg from 'images/customers/barnes-and-noble-lg.svg';
import barnesAndNobelLogoColor from 'images/customers/color-barnes-and-noble.svg';

import volkswagenLogo from 'images/customers/volkswagen.svg';
import volkswagenLogoLg from 'images/customers/volkswagen-lg.svg';
import volkswagenLogoColor from 'images/customers/color-volkswagen.svg';

import bsbBankLogo from 'images/customers/bsb-bank.svg';
import bsbBankLogoLg from 'images/customers/bsb-bank-lg.svg';
import bsbBankLogoColor from 'images/customers/color-bsb-bank.svg';

import kinveyLogo from 'images/customers/kinvey.svg';
import kinveyLogoLg from 'images/customers/kinvey-lg.svg';
import kinveyLogoColor from 'images/customers/color-kinvey.svg';

import blueprintLogo from 'images/customers/blueprint.svg';
import blueprintLogoLg from 'images/customers/blueprint-lg.svg';
import blueprintLogoColor from 'images/customers/color-blueprint.svg';

import johnsonAndJohnsonLogo from 'images/customers/johnson-and-johnson.svg';
import johnsonAndJohnsonLogoLg from 'images/customers/johnson-and-johnson-lg.svg';
import johnsonAndJohnsonLogoColor from 'images/customers/color-johnson-and-johnson.svg';

export const SECTION_TITLE = 'We are trusted by';

export const LOGOS = [
  {
    image: samsungLogo,
    imageLg: samsungLogoLg,
    imageColor: samsungLogoColor,
    alt: 'Samsung',
  },
  {
    image: evernoteLogo,
    imageLg: evernoteLogoLg,
    imageColor: evernoteLogoColor,
    alt: 'Evernote',
  },
  {
    image: theWorldBankLogo,
    imageLg: theWorldBankLogoLg,
    imageColor: theWorldBankLogoColor,
    alt: 'The world bank',
  },
  {
    image: barnesAndNobelLogo,
    imageLg: barnesAndNobelLogoLg,
    imageColor: barnesAndNobelLogoColor,
    alt: 'Barnes and Nobel',
  },
  {
    image: volkswagenLogo,
    imageLg: volkswagenLogoLg,
    imageColor: volkswagenLogoColor,
    alt: 'Volkswagen',
  },
  {
    image: bsbBankLogo,
    imageLg: bsbBankLogoLg,
    imageColor: bsbBankLogoColor,
    alt: 'BSB Bank',
  },
  {
    image: kinveyLogo,
    imageLg: kinveyLogoLg,
    imageColor: kinveyLogoColor,
    alt: 'kinvey',
  },
  {
    image: blueprintLogo,
    imageLg: blueprintLogoLg,
    imageColor: blueprintLogoColor,
    alt: 'blueprint',
  },
  {
    image: johnsonAndJohnsonLogo,
    imageLg: johnsonAndJohnsonLogoLg,
    imageColor: johnsonAndJohnsonLogoColor,
    alt: 'johnson and johnson',
  },
];
