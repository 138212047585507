import React from 'react';

import via from 'images/via.jpg';
import via2x from 'images/via@2x.jpg';

import bridges from 'images/bridges.jpg';
import bridges2x from 'images/bridges@2x.jpg';

import bubcon from 'images/bubcon.jpg';
import bubcon2x from 'images/bubcon@2x.jpg';

import bsbBank from 'images/bsb-bank.jpg';
import bsbBank2x from 'images/bsb-bank@2x.jpg';

import evoz from 'images/evoz.jpg';
import evoz2x from 'images/evoz@2x.jpg';

import blueprint from 'images/blueprint.jpg';
import blueprint2x from 'images/blueprint@2x.jpg';

import desktopIcon from 'images/desktop.svg';
import mobileIcon from 'images/mobile.svg';
import tabletIcon from 'images/tablet.svg';

export const SECTION_TITLE = 'Featured projects';

export const PROJECTS = [
  {
    image: {
      x1: via,
      x2: via2x,
    },
    name: 'VIA',
    industry: 'Transportation',
    shortDescription: 'A ride-sharing application with Salesforce integration',
    technologies: 'Android, iOS, Java, Angular.js, React.js, Salesforce',
    description: (
      <>
        <p>
          Via is an innovative application that allows commuters to share a
          drive with other passengers. Such an approach is not only
          cost-effective but environmentally conscious as well. The integration
          with Salesforce enables the app to work seamlessly under high loads
          and contributes to its performance. The product consists of several
          modules and effectively protects the stored data.
        </p>
        <ul>
          <li>Drivers’ and Riders’ modules</li>
          <li>Electronic signatures</li>
          <li>Automated Tests for use cases coverage</li>
        </ul>
      </>
    ),
    desktop: true,
    mobile: true,
    tablet: true,
  },
  {
    image: {
      x1: bridges,
      x2: bridges2x,
    },
    name: 'BRIDGES',
    industry: 'E-health',
    shortDescription:
      'A web portal for healthcare organizations and data visualization',
    technologies: 'Java, JavaScript, MySQL',
    description: (
      <>
        <p>
          Bridges is a data visualization web portal that helps healthcare
          organizations find shared focuses and expertise. Within the portal, an
          organization can create its profile and access the network of all
          Bridges participants with an aim to learn how other organizations
          reach similar goals.
        </p>
        <ul>
          <li>A solid base of medical organizations</li>
          <li>Possibilities to create surveys</li>
          <li>Powerful data visualization tool</li>
        </ul>
      </>
    ),
    desktop: true,
  },
  {
    image: {
      x1: bubcon,
      x2: bubcon2x,
    },
    name: 'BUBCON',
    industry: ' VOIP & Messaging',
    shortDescription: 'An innovative messenger for personal and corporate use',
    technologies: ' iOS, Android, XMPP',
    description: (
      <>
        <p>
          BubCon is a feature-rich messenger application that gives its users
          the right tools to easily communicate across multiple groups and
          topics with the help of secured chats and conferences guarded by
          end-to-end encryption. The solution combines custom design, advanced
          functionality and high–level security.
        </p>
        <ul>
          <li>Interactive design with a unique interface</li>
          <li>An option to create private chats and conferences</li>
          <li>Immediate password recovery and user verification</li>
        </ul>
      </>
    ),
    mobile: true,
  },
  {
    image: {
      x1: bsbBank,
      x2: bsbBank2x,
    },
    name: 'BSB Bank',
    industry: 'Finance',
    shortDescription: 'A comprehensive digital banking solution',
    technologies: 'ES6, React, Jest Java, Spring',
    description: (
      <>
        <p>
          BSB bank is a web and mobile application which allows users to access
          numerous financial operations, manage their account, and receive
          information about the bank and its services. The application also
          provides the latest information on the currency rates and availability
          of the needed currency in the ATMs.
        </p>
        <ul>
          <li>An integrated map that shows banks and ATMs</li>
          <li>Augmented reality feature for ATM detection</li>
          <li>Foreign exchange transactions</li>
        </ul>
      </>
    ),
    desktop: true,
    mobile: true,
    tablet: true,
  },
  {
    image: {
      x1: evoz,
      x2: evoz2x,
    },
    name: 'EVOZ',
    industry: 'Video Streaming',
    shortDescription: 'A comprehensive and secure baby monitoring application',
    technologies: ' iOS, Оbjective-C, Swift, AWS',
    description: (
      <>
        <p>
          Evoz is a mobile application that connects to the Evoz monitoring
          device and helps parents monitor their children through camera and
          microphone. The application sends real-time notifications as soon as
          Evoz recognizes whether the baby is crying. By using the application,
          parents can communicate with their baby at any time and from any
          place.
        </p>
        <ul>
          <li>Advanced analytics and reporting</li>
          <li>Real-time notifications</li>
          <li>Real-time video and audio monitoring</li>
        </ul>
      </>
    ),
    mobile: true,
    tablet: true,
  },
  {
    image: {
      x1: blueprint,
      x2: blueprint2x,
    },
    name: 'BLUEPRINT',
    industry: 'Business Intelligence',
    shortDescription: 'A SaaS-based BI solution for enterprises',
    technologies: 'C#, Angular.js, Ionic, MS SQL',
    description: (
      <>
        <p>
          Blueprint Storyteller is a comprehensive panel that provides visual
          modeling, automation and standardization of numerous manual processes.
          The product enables business and IT stakeholders to collaboratively
          define customer journeys, value streams, and business processes. It
          also helps stakeholders to automate business alignment throughout
          solution planning and delivery.
        </p>
        <ul>
          <li>ALM and Testing Tool Integration</li>
          <li>Usage Scenario Walkthrough</li>
          <li>Traceability and Impact Analysis</li>
        </ul>
      </>
    ),
    desktop: true,
  },
];

export const DEVICES = {
  desktop: {
    icon: desktopIcon,
    label: 'Desktop',
  },
  mobile: {
    icon: mobileIcon,
    label: 'Mobile',
  },
  tablet: {
    icon: tabletIcon,
    label: 'Tablet',
  },
};

export const VISIBLE_ELEMENTS_AMOUNT = 4;
