import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FieldWrapper, Error } from '../components/FormFields/Field';

const withActions = (Field) => {
  const FieldWithActions = ({
    name,
    handleDisableSubmit,
    validationOnBlur,
    initialErrorStatus = {},
    onChange: onChangeParent,
    disabled,
    ...rest
  }) => {
    const [error, setError] = useState('');

    useEffect(() => {
      if (initialErrorStatus[name]) setError('Field is required');
    }, [initialErrorStatus, name]);

    const onChange = (e) => {
      let isError = false;

      if (!isError && error) {
        handleDisableSubmit(false, name);
        setError('');
      }

      onChangeParent(e);
    };

    const onBlur = (e) => {
      if (validationOnBlur) {
        for (let i = 0; i < validationOnBlur.length; i++) {
          const validation = validationOnBlur[i](e.target.value);

          if (!validation.status) {
            handleDisableSubmit(true, name);
            setError(validation.message);

            break;
          }
        }
      }
    };

    return (
      <FieldWrapper disabled={disabled}>
        <Field
          onChange={onChange}
          onBlur={onBlur}
          className={error ? 'err' : ''}
          {...rest}
        />
        {error && <Error>{error}</Error>}
      </FieldWrapper>
    );
  };

  FieldWithActions.displayName = 'FieldWithActions';

  FieldWithActions.propTypes = {
    name: PropTypes.string,
    handleDisableSubmit: PropTypes.func,
    validationOnBlur: PropTypes.arrayOf(PropTypes.func),
    initialErrorStatus: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  return FieldWithActions;
};

export default withActions;
