import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import icons from 'images/icons-sprite.svg';

const ListItemWrapper = styled.li`
  min-height: 113px;
  font-size: 12px;
  text-align: left;

  @media screen and ${({ theme }) => theme.devices.lg} {
    position: relative;
    min-height: 177px;
    padding-left: 105px;
    padding-bottom: 30px;
    font-size: 16px;
    line-height: 1.56;

    :not(:last-child) {
      border-bottom: 2px solid
        rgba(${({ theme }) => theme.colors.lighterRGB}, 0.1);
    }
  }
`;

const ListItemHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ListItemIcon = styled.span`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-image: url(${icons});
  background-repeat: no-repeat;

  &.project-discovery {
    background-position: -103px -24px;
  }

  &.design {
    background-position: -103px -230px;
  }

  &.mvp {
    background-position: -103px -448px;
  }

  &.product-management {
    background-position: -103px -647px;
  }

  &.services {
    background-position: -537px -21px;
  }

  &.agile-methodology {
    background-position: -537px -226px;
  }

  &.waterfall-methodology {
    background-position: -537px -449px;
  }

  &.maintenance {
    background-position: -537px -647px;
  }

  &.efficient-candidate {
    background-position: -1024px -21px;
  }

  &.rapid-team-scaling {
    background-position: -1024px -228px;
  }

  &.access {
    background-position: -1024px -437px;
  }

  &.business {
    background-position: -1024px -642px;
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 60px;
    height: 60px;

    &.project-discovery {
      background-position: -1px -1px;
    }

    &.design {
      background-position: -1px -211px;
    }

    &.mvp {
      background-position: -1px -433px;
    }

    &.product-management {
      background-position: -1px -627px;
    }

    &.services {
      background-position: -456px -1px;
    }

    &.agile-methodology {
      background-position: -455px -210px;
    }

    &.waterfall-methodology {
      background-position: -456px -433px;
    }

    &.maintenance {
      background-position: -456px -627px;
    }

    &.efficient-candidate {
      background-position: -921px -1px;
    }

    &.rapid-team-scaling {
      background-position: -921px -210px;
    }

    &.access {
      background-position: -921px -417px;
    }

    &.business {
      background-position: -921px -627px;
    }
  }
`;

const ListItemTitle = styled.p`
  flex-grow: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  @media screen and ${({ theme }) => theme.devices.lg} {
    font-size: 26px;
  }
`;

export const ListItem = ({ className, title, description }) => (
  <ListItemWrapper>
    <ListItemHeader>
      <ListItemIcon className={className} />
      <ListItemTitle>{title}</ListItemTitle>
    </ListItemHeader>
    <p>{description}</p>
  </ListItemWrapper>
);

ListItem.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default React.memo(ListItem);
