import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Number = styled.p`
  font-size: 50px;
  line-height: 1.4;
  font-weight: 600;

  @media screen and ${({ theme }) => theme.devices.sm} {
    font-size: 70px;
    line-height: 1;
  }
`;

const Title = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.5;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 20px;
    font-size: 32px;
  }
`;

export const Item = ({ number, title, description }) => (
  <div>
    <Number>{number}</Number>
    <Title>{title}</Title>
    <p>{description}</p>
  </div>
);

Item.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
};

export default React.memo(Item);
