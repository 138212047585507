export const SECTION_TITLE = 'Welcome to digital transformation!';

export const ADDRESSES = [
  {
    city: 'Kaunas, Lithuania',
    tel: '+370 633 12233',
    address: '82 Laisves al., Kaunas, 44250, Lithuania'
  }
];

export const NAME_FIELD = 'name';
export const EMAIL_FIELD = 'email';
export const REQUEST_FIELD = 'request';
export const FILES_FIELD = 'files';
export const PRIVACY_POLICY_FIELD = 'privacyPolicy';

export const INITIAL_FIELDS_STATE = {
  [NAME_FIELD]: '',
  [EMAIL_FIELD]: '',
  [REQUEST_FIELD]: '',
  [FILES_FIELD]: [],
  [PRIVACY_POLICY_FIELD]: false,
};

export const MAX_FILES_AMOUNT = 5;

export const TIME_TO_SHOW_MESSAGES = 3000;
