import { IS_TOUCH_DEVICE } from 'constants/system';

export const widthLessThan = (size) => window.innerWidth < parseInt(size);

export const addCursorListener = (
  ref,
  cursormoveHandler,
  cursorleaveHandler,
) => {
  ref.current.addEventListener(
    `${IS_TOUCH_DEVICE ? 'touch' : 'mouse'}move`,
    cursormoveHandler,
  );

  if (cursorleaveHandler) {
    ref.current.addEventListener(
      IS_TOUCH_DEVICE ? 'touchend' : 'mouseleave',
      cursorleaveHandler,
    );
  }
};

export const removeCursorListener = (
  ref,
  cursormoveHandler,
  cursorleaveHandler,
) => {
  ref.current.removeEventListener(
    `${IS_TOUCH_DEVICE ? 'touch' : 'mouse'}move`,
    cursormoveHandler,
  );

  if (cursorleaveHandler) {
    ref.current.removeEventListener(
      IS_TOUCH_DEVICE ? 'touchend' : 'mouseleave',
      cursorleaveHandler,
    );
  }
};

export const mbToBytes = (mb) => mb * 1000 * 1024;

export const toTopPage = () => {
  document.body.scrollTo({ top: 0, behavior: 'smooth' });
  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
};

export const sendEmail = async (params) => {
  try {
    let response = await fetch('/mail/', {
      method: 'POST',
      body: Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&'),
    });

    return response.ok;
  } catch (e) {
    return false;
  }
};
