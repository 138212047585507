import React from 'react';
import styled from 'styled-components';

import { SOCIAL_NETWORKS } from 'constants/contacts';

const SocialNetworksWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  a {
    margin: 0 50px;

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }

    :hover,
    :focus {
      opacity: 0.7;
      outline: none;
    }
  }

  img {
    width: auto;
    height: 20px;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    width: auto;
    margin-bottom: 0;

    a {
      margin: 0 10px;
    }

    img {
      height: 14px;
    }
  }
`;

export const SocialNetworks = () => (
  <SocialNetworksWrapper>
    {SOCIAL_NETWORKS.map(({ icon, label, link }) => (
      <a key={label} href={link} target='_blank' rel='noreferrer'>
        <img src={icon} alt={label} />
      </a>
    ))}
  </SocialNetworksWrapper>
);

export default SocialNetworks;
