import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { THEME } from 'constants/design';

import Banner from 'components/Banner';
import Header from 'components/Header';
import Home from 'components/Home';
import AboutUs from 'components/AboutUs';
import Video from 'components/Video';
import Slider from 'components/Slider';
import Awards from 'components/Awards';
import Catalog from 'components/Catalog';
import Customers from 'components/Customers';
import Contacts from 'components/Contacts';
import Footer from 'components/Footer';

import GlobalStyle from 'styles/globalStyles';

const App = () => {
  useEffect(() => {
    const timer = setInterval(() => {
      const iframes = document.querySelectorAll('iframe');

      if (iframes.length) {
        Array.from(iframes).map((iframe) => {
          const footers = iframe.contentWindow.document.querySelectorAll(
            '.tawk-footer',
          );

          if (footers.length) {
            Array.from(footers).map(
              (footer) => (footer.firstChild.style.display = 'none'),
            );

            clearTimeout(timer);
          }
        });
      }
    }, 500);
  }, []);

  return (
    <ThemeProvider theme={THEME}>
      <Banner />
      <GlobalStyle />
      <Header />
      <Home />
      <AboutUs />
      <Video />
      <Slider />
      <Awards />
      <Catalog />
      <Customers />
      <Contacts />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
