import React from 'react';
import styled from 'styled-components';

import withActions from 'hoc/withActions';

import Field from './Field';

const Element = styled.textarea`
  ${Field}

  height: 150px;
  resize: none;
`;

export const Textarea = (props) => <Element {...props} />;

export default React.memo(withActions(Textarea));
