import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import filePending from 'images/file-pending.svg';
import fileSuccess from 'images/file-done.svg';
import fileFailure from 'images/file-failure.svg';
import fileRemove from 'images/file-remove.svg';

import { PENDING, SUCCESS, FAILURE } from 'constants/system';

import { reset } from 'components/Button';

const ICONS = {
  [PENDING]: filePending,
  [SUCCESS]: fileSuccess,
  [FAILURE]: fileFailure,
};

const RemoveButton = styled.button`
  ${reset}

  display: none;
`;

const FileWrapper = styled.li`
  display: flex;
  padding: 8px 17.5px;
  border-radius: 8px;
  cursor: default;

  > img {
    flex-shrink: 0;
    margin-right: 7px;
  }

  span {
    flex-grow: 1;
  }

  ${({ disabled, theme }) =>
    disabled
      ? ''
      : `
  :hover {
    background: rgba(${theme.colors.lighterRGB}, 0.1);

    ${RemoveButton} {
      display: inline-block;
    }
  }
  `}

  ${({ status }) => (status === FAILURE ? `color: ${({ theme }) => theme.colors.error};` : '')}
`;

export const File = ({ id, status, name, onRemove, disabled }) => (
  <FileWrapper status={ICONS[status]} disabled={disabled}>
    <img src={ICONS[status]} alt="" />
    <span>{name}</span>
    <RemoveButton onClick={() => onRemove(id)}>
      <img src={fileRemove} alt="" />
    </RemoveButton>
  </FileWrapper>
);

File.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(File);
