import React from 'react';
import styled from 'styled-components';

import { ABOUT_ID,  } from 'constants/system';

import { Wrapper as AboutUsWrapper } from 'containers/Wrapper';
import { Container as AboutUsContainer } from 'containers/Container';

import { DATA, SECTION_TITLE } from './constants';

import { Item } from './Item';

const AboutUsContent = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;

  @media screen and ${({ theme }) => theme.devices.sm} {
    grid-gap: 130px 40px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const AboutUs = () => (
  <AboutUsWrapper id={ABOUT_ID}>
    <AboutUsContainer title={SECTION_TITLE}>
      <AboutUsContent>
        {DATA.map((item) => (
          <Item key={item.title} {...item} />
        ))}
      </AboutUsContent>
    </AboutUsContainer>
  </AboutUsWrapper>
);

export default AboutUs;
