import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

const ListWrapper = styled.ul`
  display: grid;
  grid-gap: 35px;

  @media screen and ${({ theme }) => theme.devices.lg} {
    grid-gap: 30px;
  }
`;

export const List = ({ list }) => (
  <ListWrapper>
    {list.map((item) => (
      <ListItem key={item.className} {...item} />
    ))}
  </ListWrapper>
);

List.propTypes = {
  list: PropTypes.array.isRequired,
};

export default React.memo(List);
