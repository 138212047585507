export const SECTION_TITLE = 'How We Approach Your Project';

export const SLIDES = [
  {
    title: 'Startup Product Development',
    list: [
      {
        className: 'project-discovery',
        title: 'Project Discovery & Analysis',
        description:
          'Bespoke processes for creating a living technical blueprint to drive product development.',
      },
      {
        className: 'design',
        title: 'Design and Prototyping',
        description:
          'Professional UI/UX Design for all platforms and rapid, cost-effective prototyping.',
      },
      {
        className: 'mvp',
        title: 'MVP Development',
        description:
          'Fast time to market with smart MVP and POC development and subsequent expansion.',
      },
      {
        className: 'product-management',
        title: 'Product management',
        description:
          'Data-driven approach for turning the MVP into a fully-fledged digital product.',
      },
    ],
  },
  {
    title: 'Project-Based Development',
    list: [
      {
        className: 'services',
        title: 'Services',
        description:
          'One-stop solution provider for Web, Mobile and Server-Side development.',
      },
      {
        className: 'agile-methodology',
        title: 'Agile Methodology',
        description:
          'Applying best Agile software development practices through either Scrum or Kanban.',
      },
      {
        className: 'waterfall-methodology',
        title: 'Waterfall Methodology',
        description: 'Proper Waterfall approach for Fixed-Price projects.',
      },
      {
        className: 'maintenance',
        title: 'Maintenance & Support',
        description:
          'Comprehensive maintenance and support coverage with 24/7 system uptime assurance.',
      },
    ],
  },
  {
    title: 'Enterprise Team Augmentation',
    list: [
      {
        className: 'efficient-candidate',
        title: 'Efficient Candidate Selection',
        description:
          'Transparent and direct processes around candidates selection for best matching.',
      },
      {
        className: 'rapid-team-scaling',
        title: 'Rapid Team Scaling',
        description:
          'Ability to quickly upscale and downscale teams of all sizes.',
      },
      {
        className: 'access',
        title: 'Access to the Top Talent',
        description:
          'Outstanding employer reputation in local markets allows SoftTeco to attract top development talent.',
      },
      {
        className: 'business',
        title: 'Transparent & Flexible Business Approach',
        description:
          'Individual approach to every client aimed at streamlining cooperation.',
      },
    ],
  },
];
