import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  SLIDEUP_CLASS,
  SLIDEDOWN_CLASS,
  CONTACT_US_ID,
  IS_MOBILE,
} from 'constants/system';

import { reset } from 'components/Button';
import { Slide } from 'components/Animation/Slide';

import Devices from './Devices';

const Media = styled.div`
  width: 100%;
  height: 208px;
  background: ${({ theme }) => theme.colors.secondaryLight};
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${({ image }) =>
    image && image.x1
      ? `
  background-image: url(${image.x1});
  `
      : ''}
  ${({ image }) =>
    image && image.x2
      ? `
  background-image: -webkit-image-set(
    url(${image.x1}) 1x,
    url(${image.x2}) 2x
  );
  `
      : ''}

  @media screen and ${({ theme }) => theme.devices.sm} {
    flex-shrink: 0;
    width: 48%;
    height: auto;
    border-radius: 0;
  }
`;

const ProjectContainer = styled.div`
  padding: 30px 0 10px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    padding: 60px;
    padding-bottom: 40px;
    flex-grow: 1;
  }
`;

const Title = styled.p`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.43;
  text-transform: uppercase;

  @media screen and ${({ theme }) => theme.devices.sm} {
    font-size: 32px;
    line-height: 2.19;
  }
`;

const DescriptionList = styled.ul`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.43;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 100px;
    font-size: 16px;
    line-height: 1.56;
  }
`;

const Description = styled.li`
  display: flex;
  margin-bottom: 20px;

  b {
    flex-shrink: 0;
    width: 45%;
    padding-right: 10px;
  }

  span {
    flex-grow: 1;
  }
`;

const DetailedDescription = styled.div`
  ${Slide}

  p,
  ul {
    margin-bottom: 30px;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    position: absolute;
    top: 20px;
    bottom: 20px;
    z-index: 9;
    width: calc(48% - 20px);
    padding: 30px;
    font-size: 14px;
    line-height: 1.56;
    background: ${({ theme }) => theme.colors.lighter};
    opacity: 0;
    transition: .5s;
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    font-size: 16px;
  }

  @media screen and ${({ theme }) => theme.devices.xl} {
    padding: 50px 70px;
  }
`;

const MoreButton = styled.button`
  ${reset}

  display: block;
  padding: 0;
  margin: auto;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 1.33333px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: none;
  }
`;

const Info = styled.p`
  span {
    position: relative;
    top: -1px;
    padding: 0 3px;
    margin-right: 3px;
    font-size: 0.7em;
    line-height: 1;
    font-weight: 600;
    border: 1px solid ${({ theme }) => theme.colors.dark};
    border-radius: 10px;
  }

  a {
    color: ${({ theme }) => theme.colors.dark};

    :hover,
    :focus {
      color: ${({ theme }) => theme.colors.primary};
      outline: none;
    }
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    span {
      padding: 0 4px;
    }
  }
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  background: ${({ theme }) => theme.colors.lighter};
  box-shadow: 0px 5px 15px rgba(${({ theme }) => theme.colors.darkRGB}, 0.1);
  border-radius: 8px;
  overflow: hidden;

  @media screen and ${({ theme }) => theme.devices.sm} {
    position: relative;
    flex-direction: row;
    padding: 0;
    margin-bottom: 40px;

    :hover {
      ${DetailedDescription} {
        opacity: 1;
      }
    }

    &:nth-child(2n) {
      ${DetailedDescription} {
        left: 20px;
        border-radius: 10px 0px 0px 10px;
      }
    }

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;

      ${DetailedDescription} {
        right: 20px;
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
`;

export const Project = ({ project }) => {
  const {
    image,
    name,
    industry,
    shortDescription,
    technologies,
    description,
    desktop,
    mobile,
    tablet,
  } = project;

  const [isDetailedDescription, showDetailedDescription] = useState(true);
  const [slideClass, setSlideClass] = useState(IS_MOBILE ? SLIDEUP_CLASS : '');

  return (
    <ProjectWrapper
      mousemoveHandler={() => showDetailedDescription(true)}
      mouseleaveHandler={() => showDetailedDescription(false)}
    >
      <Media image={image} />
      <ProjectContainer>
        <Title>{name}</Title>

        <DescriptionList>
          <Description>
            <b>Industry</b>
            <span>{industry}</span>
          </Description>
          <Description>
            <b>Description</b>
            <span>{shortDescription}</span>
          </Description>
          <Description>
            <b>Main Technologies</b>
            <span>{technologies}</span>
          </Description>
        </DescriptionList>

        {isDetailedDescription && (
          <DetailedDescription className={slideClass}>
            {description}

            <Info>
              <span>?</span>{' '}
              <a href={`#${CONTACT_US_ID}`}>Request a detailed case study</a>
            </Info>
          </DetailedDescription>
        )}

        <MoreButton
          onClick={() =>
            setSlideClass(
              slideClass === SLIDEUP_CLASS ? SLIDEDOWN_CLASS : SLIDEUP_CLASS,
            )
          }
        >
          {slideClass === SLIDEDOWN_CLASS ? 'Hide' : 'View'} details
        </MoreButton>

        <Devices devices={{ desktop, mobile, tablet }} />
      </ProjectContainer>
    </ProjectWrapper>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired,
};

export default React.memo(Project);
