import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SIZE, IS_MOBILE } from 'constants/system';

import { addCursorListener, removeCursorListener } from 'utils';

import Animated from 'components/Animation/Animated';

const LogoWrapper = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 60px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    width: 27%;
    margin-bottom: 90px;

    :nth-child(3n - 1) {
      width: 46%;
    }
  }
`;

const LogoContainer = styled.div`
  position: relative;
  width: 100px;
  margin: auto;

  @media screen and ${({ theme }) => theme.devices.sm} {
    width: 165px;
  }
`;

const ImageOnHover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Logo = ({ image, imageLg, imageColor, alt }) => {
  const wrapperEl = useRef(null);
  const [isColorLogo, showColorLogo] = useState(false);

  useEffect(() => {
    if (!IS_MOBILE) {
      const showColor = () => {
        showColorLogo(true);
      };

      const hideColor = () => {
        showColorLogo(false);
      };

      addCursorListener(wrapperEl, showColor, hideColor);

      return () => {
        removeCursorListener(wrapperEl, showColor, hideColor);
      };
    }
  }, []);

  return (
    <LogoWrapper ref={wrapperEl}>
      <LogoContainer>
        <Animated isVisible={!isColorLogo}>
          <picture>
            <source srcSet={imageLg} media={`(min-width: ${SIZE.sm})`} />
            <img src={image} alt={alt} />
          </picture>
        </Animated>

        <Animated isVisible={isColorLogo}>
          <ImageOnHover src={imageColor} alt={alt} />
        </Animated>
      </LogoContainer>
    </LogoWrapper>
  );
};

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  imageLg: PropTypes.string.isRequired,
  imageColor: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default React.memo(Logo);
