import React from 'react';
import styled from 'styled-components';

import logo from 'images/logo-xs.svg';

import { Wrapper as FooterWrapper } from 'containers/Wrapper';
import { Container as FooterContainer } from 'containers/Container';

import SocialNetworks from './SocialNetworks';

const FooterContent = styled.div`
  @media screen and ${({ theme }) => theme.devices.sm} {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
`;

const Copyright = styled.p`
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  opacity: 0.4;

  @media screen and ${({ theme }) => theme.devices.sm} {
    flex-grow: 1;
    font-size: 10px;
    line-height: 1.8;
  }
`;

const Logo = styled.div`
  display: none;

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: block;
  }
`;

export const Footer = () => (
  <FooterWrapper
    styles={{ xs: { padding: '20px 0' }, sm: { padding: '20px 0' } }}
    themeType="dark"
  >
    <FooterContainer size="xl" styles={{ xxl: { padding: '0 40px' } }}>
      <FooterContent>
        <SocialNetworks />
        <Copyright>
          Copyright &copy; 2008-{new Date().getFullYear()} SoftTeco
        </Copyright>
        <Logo>
          <img src={logo} alt="" />
        </Logo>
      </FooterContent>
    </FooterContainer>
  </FooterWrapper>
);

export default Footer;
