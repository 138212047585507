import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import check from 'images/check.svg';

import { reset } from 'components/Button/index';

const CheckboxWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
`;

const Label = styled.label`
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  line-height: 1.43;
  color: ${({ theme }) => theme.colors.lighter};
  cursor: pointer;

  a,
  button {
    ${reset}

    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};

    :hover,
    :focus,
    :visited {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    box-sizing: border-box;
    border-radius: 2px;
  }

  ::after {
    content: url(${check});
    position: absolute;
    top: -2.5px;
    left: 5px;
    display: none;
  }
`;

const Input = styled.input`
  display: none;

  :checked + ${Label} {
    ::after {
      display: block;
    }
  }
`;

export const Checkbox = ({
  className,
  id,
  value,
  onChange,
  label,
  disabled,
}) => (
  <CheckboxWrapper className={className} disabled={disabled}>
    <Input
      type="checkbox"
      id={id}
      value={value}
      onChange={onChange}
      checked={value}
      disabled={disabled}
    />
    <Label htmlFor={id}>{label}</Label>
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(Checkbox);
