import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { IS_MOBILE } from 'constants/system';

import { addCursorListener, removeCursorListener } from 'utils';

import List from './List';

const SlideWrapper = styled.div`
  width: 100%;
  padding: 38px 10px 43px;
  margin-bottom: 45px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    width: calc(100% - 20px);
    padding: 30px 20px;
    transition: all .9s ease 0.1s;

    &.active {
      background: linear-gradient(
        1.63deg,
        ${({ theme }) => theme.colors.gradient} -1.17%,
        ${({ theme }) => theme.colors.gradient2} 102.43%
      );
      transform: translateY(-20px);
    }

    &.hide {
      opacity: 0.3;
    }
  }
`;

const SlideTitle = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;

  @media screen and ${({ theme }) => theme.devices.lg} {
    min-height: 72px;
    margin-bottom: 44px;
    font-size: 30px;
  }
`;

export const Slide = ({
  title,
  list,
  isOneSlideActive,
  setIsOneSlideActive,
}) => {
  const wrapperEl = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const isSlideActive = useCallback(
    (status) => {
      setIsHovered(status);

      if (setIsOneSlideActive) setIsOneSlideActive(status);
    },
    [setIsOneSlideActive],
  );

  useEffect(() => {
    if (!IS_MOBILE) {
      const highlightSlide = () => {
        isSlideActive(true);
      };

      const unhighlightSlide = () => {
        isSlideActive(false);
      };

      addCursorListener(wrapperEl, highlightSlide, unhighlightSlide);

      return () => {
        removeCursorListener(wrapperEl, highlightSlide, unhighlightSlide);
      };
    }
  }, [isSlideActive]);

  return (
    <SlideWrapper
      ref={wrapperEl}
      className={isHovered ? 'active' : isOneSlideActive ? 'hide' : ''}
    >
      {title && <SlideTitle>{title}</SlideTitle>}
      <List list={list} />
    </SlideWrapper>
  );
};

Slide.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array.isRequired,
  isOneSlideActive: PropTypes.bool,
  setIsOneSlideActive: PropTypes.func,
};

export default React.memo(Slide);
