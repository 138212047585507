import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { LIGHTER_THEME, LIGHT_THEME, DARK_THEME } from 'constants/design';

const Body = styled.div`
  width: 100%;
  padding: 60px 0;
  ${({ theme, themeType }) => {
    let color = '';
    let bg = '';

    switch (themeType) {
      case LIGHT_THEME:
        color = theme.colors.dark;
        bg = theme.colors.light;
        break;
      case LIGHTER_THEME:
        color = theme.colors.dark;
        bg = theme.colors.leghter;
        break;
      case DARK_THEME:
        color = theme.colors.lighter;
        bg = theme.colors.dark;
        break;
    }

    return `
      color: ${color};
      background: ${bg};
    `;
  }}
  transition: 1s;

  ${({ styles }) => styles.xs || ''}

  @media screen and ${({ theme }) => theme.devices.sm} {
    padding: 100px 0 80px;

    ${({ styles }) => styles.sm || ''}
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    ${({ styles }) => styles.lg || ''}
  }

  @media screen and ${({ theme }) => theme.devices.xl} {
    ${({ styles }) => styles.xl || ''}
  }
`;

export const Wrapper = ({
  id,
  styles = {},
  children,
  themeType = LIGHTER_THEME,
}) => (
  <Body id={id} styles={styles} themeType={themeType}>
    {children}
  </Body>
);

Wrapper.propTypes = {
  id: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
  themeType: PropTypes.string,
};

export default React.memo(Wrapper);
