import React from 'react';
import styled from 'styled-components';

import { EMAIL } from 'constants/contacts';

import { ADDRESSES } from './constants';
import Address from './Address';

const InfoWrapper = styled.div`
  width: 100%;
  padding: 50px 40px;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;

  a {
    color: ${({ theme }) => theme.colors.lighter};
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    flex-shrink: 0;
    width: 350px;
    background: rgba(${({ theme }) => theme.colors.lighterRGB}, 0.1);
    border: none;
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    width: 400px;
    padding: 70px;
    padding-bottom: 50px;
  }
`;

const InfoTitle = styled.p`
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 30px;
  line-height: 2.19;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 20px;
  }
`;

const Email = styled.div`
  margin-bottom: 30px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 45px;
  }
`;

const EmailLabel = styled.b`
  display: none;

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: inline-block;
    margin-right: 10px;
  }
`;

const EmailLink = styled.a`
  :hover,
  :focus {
    text-decoration: underline;
    outline: none;
  }
`;

export const Info = () => (
  <InfoWrapper>
    <InfoTitle>Our contacts</InfoTitle>
    <Email>
      <EmailLabel>Email:</EmailLabel>
      <EmailLink href={`mailto:${EMAIL}`}>{EMAIL}</EmailLink>
    </Email>
    {ADDRESSES.map((address) => (
      <Address key={address.tel} {...address} />
    ))}
  </InfoWrapper>
);

export default Info;
