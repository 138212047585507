import React from 'react';
import styled from 'styled-components';

import { Wrapper as CustomersWrapper } from 'containers/Wrapper';
import { Container as CustomersContainer } from 'containers/Container';

import { LOGOS, SECTION_TITLE } from './constants';

import Logo from './Logo';

const CustomersContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -60px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: -90px;
  }
`;

export const Customers = () => (
  <CustomersWrapper>
    <CustomersContainer title={SECTION_TITLE}>
      <CustomersContent>
        {LOGOS.map((logo) => (
          <Logo key={logo.alt} {...logo} />
        ))}
      </CustomersContent>
    </CustomersContainer>
  </CustomersWrapper>
);

export default Customers;
