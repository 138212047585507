import goodFirm from 'images/awards/good-firms.jpg';
import goodFirm2x from 'images/awards/good-firms@2x.jpg';
import networkBelarus from 'images/awards/network-belarus.svg';
import softwareworld from 'images/awards/softwareworld.png';
import softwareworld2x from 'images/awards/softwareworld@2x.png';
import topMobileAppDevelopmentCompanies from 'images/awards/top-mobile-app-development-companies.png';
import clutch from 'images/awards/clutch.png';
import clutch2x from 'images/awards/clutch@2x.png';
import iso27001 from 'images/awards/iso-27001.png';
import iso270012x from 'images/awards/iso-27001@2x.png';
import htp from 'images/awards/htp.svg';
import bestITCompanies from 'images/awards/best-it-companies.svg';
import digitalKnights from 'images/awards/digital-knights.svg';
import bestITCompanies2 from 'images/awards/best-it-companies-2.svg';
import iso9001 from 'images/awards/iso-9001.svg';

export const AWARDS = [
  {
    image: iso9001,
    width: 110,
    alt: 'ISO 9001',
  },
  {
    image: iso27001,
    image2x: iso270012x,
    width: 116,
    alt: 'ISO 27001',
  },
  {
    image: clutch,
    image2x: clutch2x,
    width: 116,
    alt: 'Clutch',
  },
  {
    image: softwareworld,
    image2x: softwareworld2x,
    width: 127,
    alt: 'SoftwareWorld',
  },
  {
    image: digitalKnights,
    width: 111,
    alt: 'Digital Knights',
  },
  {
    image: bestITCompanies2,
    width: 198,
    alt: 'Best IT Companies',
  },
  {
    image: bestITCompanies,
    width: 198,
    alt: 'Best IT Companies',
  },
  {
    image: goodFirm,
    image2x: goodFirm2x,
    width: 137,
    alt: 'Good Firm',
  },
  {
    image: topMobileAppDevelopmentCompanies,
    width: 177,
    alt: 'Top Mobile App Development Companies',
  },
  {
    image: networkBelarus,
    width: 125,
    alt: 'Network Belarus',
  },
  {
    image: htp,
    width: 143,
    alt: 'HTP',
  },
];
