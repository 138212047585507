import React, { useState } from 'react';
import styled from 'styled-components';

import arrow from 'images/arrow-down.svg';

import { SLIDEUP_CLASS, SLIDEDOWN_CLASS, PORTFOLIO_ID } from 'constants/system';
import { LIGHT_THEME } from 'constants/design';

import { Wrapper as CatalogWrapper } from 'containers/Wrapper';
import { Container as CatalogContainer } from 'containers/Container';

import { reset } from 'components/Button';
import { Slide } from 'components/Animation/Slide';

import { PROJECTS, VISIBLE_ELEMENTS_AMOUNT, SECTION_TITLE } from './constants';

import Project from './Project';

const MoreButton = styled.button`
  ${reset}

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 1.33333px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.lighter};
  box-shadow: 0px 5px 15px rgba(${({ theme }) => theme.colors.darkRGB}, 0.1);
  border-radius: 8px;

  img {
    margin-right: 10px;
  }

  :hover {
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`;

const HiddenElements = styled.div`
  width: 100%;

  ${Slide}
`;

export const Catalog = () => {
  const [slideClass, setSlideClass] = useState(SLIDEUP_CLASS);

  const visibleElements = PROJECTS.filter((item, index) => index < VISIBLE_ELEMENTS_AMOUNT);
  const hiddenElements = PROJECTS.filter((item, index) => index >= VISIBLE_ELEMENTS_AMOUNT);

  return (
    <CatalogWrapper id={PORTFOLIO_ID} themeType={LIGHT_THEME}>
      <CatalogContainer title={SECTION_TITLE}>
        <div>
          {visibleElements.map((project) => (
            <Project key={project.name} project={project} />
          ))}

          {slideClass === SLIDEUP_CLASS && (
            <MoreButton onClick={() => setSlideClass(SLIDEDOWN_CLASS)}>
              <img src={arrow} alt="" />
              <span>More</span>
            </MoreButton>
          )}

          <HiddenElements className={slideClass}>
            {hiddenElements.map((project) => (
              <Project key={project.name} project={project} />
            ))}
          </HiddenElements>
        </div>
      </CatalogContainer>
    </CatalogWrapper>
  );
};

export default Catalog;
