import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SERVICE_ID, ABOUT_ID, PORTFOLIO_ID } from 'constants/system';

const MenuWrapper = styled.div`
  display: none;
  flex-grow: 1;
  justify-content: center;

  a {
    margin: 0 15px;
    font-size: 14px;
    line-height: 1.2;
    text-decoration: none;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.lighter};
    transition: 1s;

    :hover,
    :focus {
      text-decoration: underline;
      outline: none;
    }
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: flex;

    a {
      ${({ isRevert, theme }) =>
        isRevert
          ? `
      color: ${theme.colors.dark};

      :hover,
      :focus {
        text-decoration: none;
        color: ${theme.colors.primary};
      }`
          : ''}
    }
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    a {
      margin: 0 25px;
      font-size: 18px;
    }
  }
`;

export const Menu = ({ isRevert }) => (
  <MenuWrapper isRevert={isRevert}>
    <a href={`#${ABOUT_ID}`}>About Us</a>
    <a href={`#${SERVICE_ID}`}>Service</a>
    <a href={`#${PORTFOLIO_ID}`}>Portfolio</a>
  </MenuWrapper>
);

Menu.propTypes = {
  isRevert: PropTypes.bool,
};

export default React.memo(Menu);
