import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { SERVICE_ID, IS_MOBILE } from 'constants/system';

import { Wrapper as SliderWrapper } from 'containers/Wrapper';
import { Container as SliderContainer } from 'containers/Container';

import { SECTION_TITLE, SLIDES } from './constants';

import Slide from './Slide';

const responsive = {
  desktop: {
    breakpoint: { max: 100000, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const SliderContent = styled.div`
  .slider-dots {
    bottom: 10px;

    button {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      border: none;
      background: rgba(${({ theme }) => theme.colors.lighterRGB}, 0.2);
    }

    [class*='active'] button {
      background: ${({ theme }) => theme.colors.lighter};
    }
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    .slides {
      overflow: visible;
    }
  }
`;

export const Slider = () => {
  const [isOneSlideActive, setIsOneSlideActive] = useState(false);

  return (
    <SliderWrapper
      id={SERVICE_ID}
      styles={{ xs: { paddingBottom: 0 } }}
      themeType="dark"
    >
      <SliderContainer title={SECTION_TITLE} size="xl">
        <SliderContent>
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={['mobile', 'desktop']}
            additionalTransfrom={0}
            centerMode={false}
            customTransition={`all 1s linear`}
            infinite={IS_MOBILE}
            keyBoardControl
            minimumTouchDrag={80}
            slidesToSlide={1}
            swipeable={IS_MOBILE}
            transitionDuration={1}
            containerClass="slides"
            showDots={IS_MOBILE}
            dotListClass="slider-dots"
          >
            {SLIDES.map((slide) => (
              <Slide
                key={slide.title}
                {...slide}
                isOneSlideActive={isOneSlideActive}
                setIsOneSlideActive={setIsOneSlideActive}
              />
            ))}
          </Carousel>
        </SliderContent>
      </SliderContainer>
    </SliderWrapper>
  );
};

export default Slider;
