import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { SUCCESS } from 'constants/system';

import { validateEmail } from 'components/FormFields/validation';
import { sendEmail } from 'utils';

import Input from 'components/FormFields/Input';
import Textarea from 'components/FormFields/Textarea';
import Button from 'components/Button';
import Attachment from 'components/FormFields/Attachment/index';
import Checkbox from 'components/FormFields/Checkbox';
import PrivacyPolicy from 'components/PrivacyPolicy/index';
import Animated from 'components/Animation/Animated';

import {
  NAME_FIELD,
  EMAIL_FIELD,
  REQUEST_FIELD,
  FILES_FIELD,
  PRIVACY_POLICY_FIELD,
  INITIAL_FIELDS_STATE,
  MAX_FILES_AMOUNT,
  TIME_TO_SHOW_MESSAGES,
} from './constants';

const FormWrapper = styled.div`
  margin-bottom: 40px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 0;
    margin-right: 70px;
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    margin-bottom: 0;
    margin-right: 140px;
  }
`;

const FormDescription = styled.p`
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;

  @media screen and ${({ theme }) => theme.devices.sm} {
    text-align: left;
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 1.33;
  }
`;

const FormTag = styled.form`
  display: flex;
  flex-direction: column;

  input[type='text'] {
    margin-bottom: 30px;
  }

  textarea {
    margin-bottom: 20px;
  }

  .attachment {
    margin-bottom: 30px;
  }

  .privacy-policy {
    margin-bottom: 60px;
    margin-left: 3px;
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    input[type='text'] {
      margin-bottom: 20px;
    }

    textarea {
      margin-bottom: 10px;
    }

    .privacy-policy {
      margin-bottom: 40px;
      margin-left: 17px;
    }
  }
`;

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const InfoMessage = css`
  position: absolute;
  top: 100%;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  font-style: italic;
`;

const SuccessMessage = styled.span`
  ${InfoMessage}

  color: ${({ theme }) => theme.colors.success};
`;
const ErrorMessage = styled.span`
  ${InfoMessage}

  color: ${({ theme }) => theme.colors.error};
`;

const Submit = styled.input`
  ${Button}

  padding: 20px;
  font-size: 16px;
  line-height: 0.75;
  letter-spacing: 1.33333px;

  @media screen and ${({ theme }) => theme.devices.lg} {
    width: 360px;
    margin: 0 auto;
  }
`;

export const Form = () => {
  const [formFields, setFromFields] = useState({
    ...INITIAL_FIELDS_STATE,
  });
  const [disabledSubmitProperties, setDisabledSubmitProperties] = useState([
    PRIVACY_POLICY_FIELD,
  ]);
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(
    false,
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSubmitFailed, setIsFormSubmitFailed] = useState(false);
  const [initialErrorStatus, setInitialErrorStatus] = useState({});
  const [isDataSending, setDataSending] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(null);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const errorStatus = {
        [NAME_FIELD]: !formFields[NAME_FIELD],
        [EMAIL_FIELD]: !formFields[EMAIL_FIELD],
        [REQUEST_FIELD]: !formFields[REQUEST_FIELD],
        [PRIVACY_POLICY_FIELD]: !formFields[PRIVACY_POLICY_FIELD],
      };

      const activeErrors = Object.keys(errorStatus).filter(
        (name) => errorStatus[name],
      );

      if (activeErrors.length) {
        setInitialErrorStatus({ ...initialErrorStatus, ...errorStatus });
        setDisabledSubmitProperties([
          ...disabledSubmitProperties,
          ...activeErrors,
        ]);
      } else {
        setDataSending(true);

        const files = formFields[FILES_FIELD].filter(
          (file) => file.status === SUCCESS,
        ).map(({ name, path }) => ({
          name,
          path,
        }));

        const params = {
          from_name: formFields[NAME_FIELD],
          from_email: formFields[EMAIL_FIELD],
          message: formFields[REQUEST_FIELD],
          files: JSON.stringify(files),
          privacy_policy: formFields[PRIVACY_POLICY_FIELD],
        };

        const isEmailSent = await sendEmail(params);

        setIsEmailSent(isEmailSent)
      }
    },
    [formFields],
  );

  const handleDisabledSubmitProperties = useCallback(
    (status, property) => {
      if (status && !disabledSubmitProperties.includes(property)) {
        setDisabledSubmitProperties([...disabledSubmitProperties, property]);
      }

      if (!status && disabledSubmitProperties.includes(property)) {
        setDisabledSubmitProperties(
          disabledSubmitProperties.filter((item) => item !== property),
        );
      }
    },
    [disabledSubmitProperties],
  );

  useEffect(() => {
    if (typeof isEmailSent === 'boolean') {
      if (isEmailSent) {
        setIsFormSubmitted(true);
        setFromFields({ ...INITIAL_FIELDS_STATE });
        setDisabledSubmitProperties([PRIVACY_POLICY_FIELD]);
        setInitialErrorStatus({});
        setDataSending(false);
      } else {
        setIsFormSubmitFailed(true);
        setDataSending(false);
      }

      setIsEmailSent(null);
    }
  }, [isEmailSent]);

  useEffect(() => {
    if (isFormSubmitted || isFormSubmitFailed) {
      setTimeout(() => {
        if (isFormSubmitted) {
          setIsFormSubmitted(false);
        } else {
          setIsFormSubmitFailed(false);
        }
      }, TIME_TO_SHOW_MESSAGES);
    }
  }, [isFormSubmitted, isFormSubmitFailed]);

  const updateFormField = useCallback(
    (key, value) => setFromFields({ ...formFields, [key]: value }),
    [formFields],
  );

  return (
    <FormWrapper>
      <PrivacyPolicy
        isVisible={isPrivacyPolicyModalOpen}
        onClose={() => setIsPrivacyPolicyModalOpen(false)}
      />

      <FormDescription>
        Send us your request and we will answer you within one working day!
      </FormDescription>
      <FormTag onSubmit={handleSubmit}>
        <Input
          value={formFields[NAME_FIELD]}
          onChange={(e) => updateFormField(NAME_FIELD, e.target.value)}
          placeholder="Name"
          handleDisableSubmit={handleDisabledSubmitProperties}
          name={NAME_FIELD}
          initialErrorStatus={initialErrorStatus}
          disabled={isDataSending}
        />
        <Input
          value={formFields[EMAIL_FIELD]}
          onChange={(e) => updateFormField(EMAIL_FIELD, e.target.value)}
          placeholder="Email"
          validationOnBlur={[validateEmail]}
          handleDisableSubmit={handleDisabledSubmitProperties}
          name={EMAIL_FIELD}
          initialErrorStatus={initialErrorStatus}
          disabled={isDataSending}
        />
        <Textarea
          value={formFields[REQUEST_FIELD]}
          onChange={(e) => updateFormField(REQUEST_FIELD, e.target.value)}
          placeholder="Please describe your request"
          handleDisableSubmit={handleDisabledSubmitProperties}
          name={REQUEST_FIELD}
          initialErrorStatus={initialErrorStatus}
          disabled={isDataSending}
        />
        <Attachment
          className="attachment"
          label={`Load attachment`}
          loadedFiles={[...formFields[FILES_FIELD]]}
          onChange={(files) => updateFormField(FILES_FIELD, files)}
          id="file"
          name={FILES_FIELD}
          disabled={isDataSending}
          maxAmount={MAX_FILES_AMOUNT}
        />
        <Checkbox
          className="privacy-policy"
          id="privacy-policy"
          value={formFields[PRIVACY_POLICY_FIELD]}
          onChange={() => {
            handleDisabledSubmitProperties(
              formFields[PRIVACY_POLICY_FIELD],
              PRIVACY_POLICY_FIELD,
            );
            updateFormField(
              PRIVACY_POLICY_FIELD,
              !formFields[PRIVACY_POLICY_FIELD],
            );
          }}
          label={
            <>
              I agree with{' '}
              <button
                type="button"
                onClick={() => setIsPrivacyPolicyModalOpen(true)}
              >
                Privacy Policy
              </button>
            </>
          }
          name={PRIVACY_POLICY_FIELD}
          disabled={isDataSending}
        />
        <FormFooter>
          <Submit
            type="submit"
            value={isDataSending ? 'Sending...' : 'Send Request'}
            disabled={disabledSubmitProperties.length || isDataSending}
          />
          <Animated isVisible={isFormSubmitted}>
            <SuccessMessage>
              Your request has been submitted. Thank you.
            </SuccessMessage>
          </Animated>
          <Animated isVisible={isFormSubmitFailed}>
            <ErrorMessage>Something was wrong</ErrorMessage>
          </Animated>
        </FormFooter>
      </FormTag>
    </FormWrapper>
  );
};

export default Form;
