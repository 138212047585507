import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { DEVICES } from './constants';

const DevicesWrapper = styled.ul`
  display: none;

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: flex;
    opacity: 0.3;
  }
`;

const Device = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;

  img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }
`;

export const Devices = ({ devices }) => {
  return (
    <DevicesWrapper>
      {Object.keys(devices).map(
        (key) =>
          devices[key] && (
            <Device key={key}>
              <img src={DEVICES[key].icon} alt={DEVICES[key].label} />
              <span>{DEVICES[key].label}</span>
            </Device>
          ),
      )}
    </DevicesWrapper>
  );
};

Devices.propTypes = {
  devices: PropTypes.object,
};

export default React.memo(Devices);
