import React from 'react';
import { Animated as AnimatedWrapper } from 'react-animated-css';
import PropTypes from 'prop-types';

export const Animated = ({ isVisible, children }) => {
  return (
    <AnimatedWrapper
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={isVisible}
    >
      {children}
    </AnimatedWrapper>
  );
};

Animated.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(Animated);
