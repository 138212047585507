import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import logo from 'images/logo.svg';
import logoLg from 'images/logo-lg.svg';
import logoColor from 'images/color-logo.svg';

import { CONTACT_US_ID, SIZE, IS_MOBILE, HOME_ID } from 'constants/system';
import {
  LIGHTER_COLOR_RGB,
  SHADOW_COLOR_RGB,
  LIGHTER_THEME,
  DARK_THEME,
} from 'constants/design';

import { toTopPage } from 'utils';

import { Wrapper as HeaderWrapper } from 'containers/Wrapper';
import { Container as HeaderContainer } from 'containers/Container';

import {
  Button as DefaultButton,
  reset,
  SecondaryButton,
} from 'components/Button';

import Menu from './Menu';

const HeaderContent = styled.div`
  img {
    max-width: 139px;
    margin: 0 auto;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: flex;
    align-items: center;

    img {
      flex-shrink: 0;
    }
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    img {
      max-width: 217px;
    }
  }
`;

const Button = styled.a`
  ${({ isRevert }) => (isRevert ? DefaultButton : SecondaryButton)}

  display: none;
  padding: 12px 25px;
  font-size: 14px;
  line-height: 1.3;
  transition: 1s;

  @media screen and ${({ theme }) => theme.devices.sm} {
    display: block;
  }
`;

const Logo = styled.button`
  ${reset}

  img {
    transition: 1s;
  }
`;

export const Header = () => {
  const [isRevert, setIsRevert] = useState(false);

  useEffect(() => {
    if (!IS_MOBILE) {
      const homeSection = document.getElementById(HOME_ID);
      const homeSectionOffsetBottom =
        homeSection.offsetTop + homeSection.offsetHeight;

      window.addEventListener(
        'scroll',
        () => {
          if (window.scrollY >= homeSectionOffsetBottom && !isRevert) {
            setIsRevert(true);
          } else if (window.scrollY < homeSectionOffsetBottom && isRevert) {
            setIsRevert(false);
          }
        },
        false,
      );

      return () => {
        window.removeEventListener('scroll', () => {});
      };
    }
  }, [isRevert]);

  const smStyles = {
    padding: '30px 0',
    position: 'sticky',
    top: 0,
    zIndex: 99,
  };
  if (isRevert) {
    smStyles.backgroundColor = `rgba(${LIGHTER_COLOR_RGB}, 0.8)`;
    smStyles.boxShadow = `0px 4px 8px rgba(${SHADOW_COLOR_RGB}, 0.2)`;
  }

  return (
    <HeaderWrapper
      styles={{
        xs: { padding: '30px 0' },
        sm: smStyles,
      }}
      themeType={isRevert ? LIGHTER_THEME : DARK_THEME}
    >
      <HeaderContainer size="xl" styles={{ xxl: { padding: '0 40px' } }}>
        <HeaderContent>
          <Logo onClick={toTopPage}>
            <picture>
              <source
                srcSet={isRevert ? logoColor : logoLg}
                media={`(min-width: ${SIZE.lg})`}
              />
              <img src={isRevert ? logoColor : logo} alt="" />
            </picture>
          </Logo>

          <Menu isRevert={isRevert} />
          <Button href={`#${CONTACT_US_ID}`} isRevert={isRevert}>
            Contact us
          </Button>
        </HeaderContent>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
