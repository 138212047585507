import styled, { css } from 'styled-components';

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
`;

export const Field = css`
  width: 100%;
  padding: 15px;
  background: ${({ theme }) => theme.colors.lighter};
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.lighter};

  ::placeholder {
    color: rgb(${({ theme }) => theme.colors.darkRGB}, 0.7);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(${({ theme }) => theme.colors.darkRGB}, 0.7);
  }

  ::-ms-input-placeholder {
    color: rgb(${({ theme }) => theme.colors.darkRGB}, 0.7);
  }

  :focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  &.err {
    padding-right: 145px;
    border-color: ${({ theme }) => theme.colors.error};
  }
`;

export const Error = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  width: 130px;
  font-size: 14px;
  line-height: 1.43;
  color: ${({ theme }) => theme.colors.error};

  ::before {
    content: 'i';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-right: 7px;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid ${({ theme }) => theme.colors.error};
    border-radius: 50%;
  }
`;

export default Field;
