import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SIZE } from 'constants/system';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: ${({ size }) => (size === 'xl' ? SIZE.xxl : SIZE.xl)};
  padding: 0 20px;
  margin: 0 auto;

  ${({ styles }) => styles.xs || ''}

  @media screen and ${({ theme }) => theme.devices.sm} {
    ${({ styles }) => styles.sm || ''}
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    ${({ styles }) => styles.lg || ''}
  }

  @media screen and ${({ theme }) => theme.devices.xl} {
    ${({ styles }) => styles.xl || ''}
  }

  @media screen and ${({ theme }) => theme.devices.xxl} {
    ${({ styles }) => styles.xxl || ''}
  }
`;

const ContainerHeader = styled.header`
  margin-bottom: 40px;

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 80px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.35;

  @media screen and ${({ theme }) => theme.devices.sm} {
    font-size: 50px;
    line-height: 1.5;
  }
`;

export const Container = ({
  title,
  size = 'default',
  styles = {},
  children,
}) => (
  <ContainerWrapper size={size} styles={styles}>
    {title && (
      <ContainerHeader>
        <Title>{title}</Title>
      </ContainerHeader>
    )}
    {children}
  </ContainerWrapper>
);

Container.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default React.memo(Container);
