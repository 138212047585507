import { css } from 'styled-components';

import { SLIDEUP_CLASS, SLIDEDOWN_CLASS } from 'constants/system';

export const Slide = css`
  &.${SLIDEUP_CLASS}, &.${SLIDEDOWN_CLASS} {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  &.${SLIDEDOWN_CLASS} {
    max-height: 2000px;
  }
`;
