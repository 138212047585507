import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AddressWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    :not(:last-child) {
      margin-bottom: 45px;
    }
  }
`;

const AddressTitle = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.67;
`;

const Tel = styled.a`
  display: inline-block;
  margin-bottom: 15px;
  text-decoration: none;
  word-break: keep-all;

  :hover,
  :focus {
    text-decoration: underline;
    outline: none;
  }
`;

export const Address = ({ city, tel, address }) => (
  <AddressWrapper>
    <AddressTitle>
      <strong>{city}</strong>
    </AddressTitle>
    <Tel href={`tel:${tel}`}>{tel}</Tel>
    <p>{address}</p>
  </AddressWrapper>
);

Address.propTypes = {
  city: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default React.memo(Address);
