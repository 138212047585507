import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import icon from 'images/file-add.svg';

import { PENDING, SUCCESS, FAILURE } from 'constants/system';

import { mbToBytes } from 'utils';

import Animated from 'components/Animation/Animated';

import {
  MAX_FILES_SIZE_MB,
  ACCEPTED_FILES,
  TIME_TO_SHOW_MESSAGES,
} from '../constants';

import Files from './Files';

const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 17.5px 10px 40.5px;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(${({ theme }) => theme.colors.lighter}, 0.7);
  background: rgba(${({ theme }) => theme.colors.lighterRGB}, 0.1);
  border: 1px dashed ${({ theme }) => theme.colors.lighter};
  border-radius: 8px;

  ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}

  img {
    position: absolute;
    top: 12px;
    left: 17.5px;
  }
`;

const Error = styled.span`
  position: absolute;
  top: calc(100% + 4px);
  left: 40.5px;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.error};
  opacity: 0.8;
`;

const AttachmentWrapper = styled.div`
  position: relative;

  :hover,
  :focus {
    outline: none;

    ${Label} {
      border-color: rgba(${({ theme }) => theme.colors.lighterRGB}, 0.7);
    }
  }

  input {
    display: none;
  }
`;

export const Attachment = ({
  className,
  label,
  onChange,
  id,
  name,
  loadedFiles,
  disabled,
  maxAmount,
}) => {
  const [error, setError] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      let isValid = true;

      if (maxAmount && loadedFiles.length + acceptedFiles.length > maxAmount) {
        setError(`Files limit exceeded - ${maxAmount} max`);

        isValid = false;
      }

      if (isValid) {
        acceptedFiles.forEach((file) => {
          const { name, size } = file;

          const allFilesSize = loadedFiles.reduce(
            (prev, cur) => prev + cur.size,
            0,
          );
          if (allFilesSize + size < mbToBytes(MAX_FILES_SIZE_MB)) {
            const loadedTime = Date.now();
            const id = (name + loadedTime).replace(/\s+/g, '').trim();

            const fileData = { id, name, status: PENDING, size };

            const reader = new FileReader();

            reader.onload = () => {
              fileData.status = SUCCESS;
              fileData.path = reader.result;

              onChange([...loadedFiles]);
            };

            reader.onerror = () => {
              fileData.status = FAILURE;

              onChange([...loadedFiles]);
            };

            reader.readAsDataURL(file);

            loadedFiles.push(fileData);
          } else {
            setError(`Maximum files size exceeded - ${MAX_FILES_SIZE_MB}mb`);
          }
        });

        onChange(loadedFiles);
      }
    },
    [onChange, loadedFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_FILES,
    onDrop,
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, TIME_TO_SHOW_MESSAGES);
    }
  }, [error]);

  return (
    <div style={{ opacity: disabled ? '0.7' : '1' }}>
      <AttachmentWrapper
        {...getRootProps({
          className: `${className} dropzone`,
        })}
      >
        <input {...getInputProps()} name={name} disabled={disabled} />
        <Label htmlFor={id} disabled={disabled}>
          <img src={icon} alt="" />
          {label}
        </Label>
        <Animated isVisible={!!error}>
          <Error>{error}</Error>
        </Animated>
      </AttachmentWrapper>
      {loadedFiles && (
        <Files
          files={[...loadedFiles]}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

Attachment.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  loadedFiles: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  maxAmount: PropTypes.number,
};

export default React.memo(Attachment);
