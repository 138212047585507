import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { IS_MOBILE } from 'constants/system';

import { Container as AwardsContainer } from 'containers/Container';

import { AWARDS } from './constants';

const responsive = {
  desktop: {
    breakpoint: { max: 100000, min: 1200 },
    items: 5,
  },
  desktopSm: {
    breakpoint: { max: 1199, min: 992 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 451 },
    items: 2,
  },
  mobileSm: {
    breakpoint: { max: 450, min: 0 },
    items: 1,
  },
};

const AwardsWrapper = styled.div`
  padding: 0;

  img {
    height: auto;
  }

  .award-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    .awards {
      position: relative;

      ::after,
      ::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 9;
        display: block;
        width: 270px;
        ${({ theme }) => `
          background: linear-gradient(
            87.3deg,
            ${theme.colors.lighter} 2.08%,
            rgba(${theme.colors.lighterRGB}, 0.57) 50.35%,
            rgba(${theme.colors.lighterRGB}, 0) 97.63%
          );
        `}
      }

      ::after {
        right: -1px;
        transform: rotate(-180deg);
      }

      ::before {
        left: -1px;
      }
    }
  }
`;

export const Awards = () => (
  <AwardsWrapper>
    <AwardsContainer size="xl" styles={{ xs: { padding: '25px 0' } }}>
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={[
          'mobileSm',
          'mobile',
          'tablet',
          'desktopSm',
          'desktop',
        ]}
        additionalTransfrom={0}
        autoPlay={!IS_MOBILE}
        autoPlaySpeed={1}
        centerMode={false}
        customTransition={`all ${IS_MOBILE ? 1 : 3}s linear`}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        slidesToSlide={1}
        swipeable
        transitionDuration={IS_MOBILE ? 1 : 3000}
        itemClass="award-item"
        containerClass="awards"
      >
        {AWARDS.map(({ image, image2x, width, alt }) => (
          <img
            src={image}
            srcSet={image2x ? image2x + ' 2x' : null}
            width={width}
            alt={alt}
            key={alt + width}
          />
        ))}
      </Carousel>
    </AwardsContainer>
  </AwardsWrapper>
);

export default Awards;
