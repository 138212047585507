import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import close from 'images/light-close.svg';

import { IS_TABLET } from 'constants/system';

import { reset } from 'components/Button/index';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(${({ theme }) => theme.colors.darkRGB}, 0.6);
`;

const ModalContainer = styled.div`
  position: relative;
  z-index: 5;
  padding: ${IS_TABLET ? '30px' : '40px 50px'};
  color: ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme }) => theme.colors.lighter};
  box-shadow: 0px 5px 15px rgba(${({ theme }) => theme.colors.darkRGB}, 0.1);
  overflow-y: auto;
`;

const ModalTitle = styled.h2`
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 25px;
  line-height: 1.3;

  @media@media screen and ${({ theme }) => theme.devices.sm} {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 2.19;
  }
`;

const ModalDescription = styled.div`
  p {
    margin-bottom: 15px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    :hover,
    :focus {
      opacity: 0.7;
    }
  }
`;

const CloseButton = styled.button`
  ${reset}

  position: absolute;
  top: ${({ withImage }) => (withImage ? 18 : 25)}px;
  right: ${({ withImage }) => (withImage ? 22 : 20)}px;
  width: 20px;
  height: 20px;
  font-size: 0;

  ${({ theme, withImage }) =>
    !withImage
      ? `
  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 9px;
    left: -2px;
    display: block;
    width: 25px;
    height: 1px;
    background-color: ${theme.colors.dark};
  }
  `
      : ''}

  ::after {
    transform: rotate(45deg);
  }

  ::before {
    transform: rotate(-45deg);
  }
`;

export const Modal = ({
  isVisible,
  onClose,
  children,
  title,
  styles = {},
  showCloseBtn,
}) =>
  isVisible ? (
    <ModalWrapper>
      <Background onClick={onClose} />
      <ModalContainer style={styles}>
        {showCloseBtn && (
          <CloseButton withImage onClick={onClose}>
            <img src={close} alt="" />
          </CloseButton>
        )}
        {title && (
          <>
            <CloseButton onClick={onClose}>Close</CloseButton>
            <ModalTitle>{title}</ModalTitle>
          </>
        )}

        <ModalDescription>{children}</ModalDescription>
      </ModalContainer>
    </ModalWrapper>
  ) : null;

Modal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  styles: PropTypes.object,
  showCloseBtn: PropTypes.bool,
};

export default React.memo(Modal);
