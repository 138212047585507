import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import homeBg from 'images/home-bg.svg';
import homeBgLg from 'images/home-bg-lg.svg';

import { HOME_ID, IS_TABLET, CORPORATE_SITE_LINK } from 'constants/system';
import { GRADIENT_COLOR, GRADIENT_COLOR_2 } from 'constants/design';

import { addCursorListener, removeCursorListener } from 'utils';

import { Wrapper as HomeWrapper } from 'containers/Wrapper';
import { Container as HomeContainer } from 'containers/Container';

import ButtonStyle from 'components/Button';

import { SECTION_TITLE, SECTION_DESCRIPTION } from './constants';

const TitleContent = styled.div`
  padding: 155px 10px;
  margin: 0 -20px 60px;
  background-image: url(${homeBg});
  background-position-x: -40px;
  background-repeat: no-repeat;

  h1 {
    width: 250px;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.33;
    text-transform: uppercase;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    padding: 190px 10px 200px;
    margin-bottom: 0;
    background-position-x: -30px;
    background-size: auto 100%;

    h1 {
      width: 350px;
      font-weight: 800;
      font-size: 40px;
      line-height: 1.14;
    }
  }

  @media screen and ${({ theme }) => theme.devices.lg} {
    position: relative;
    padding: 404px 0px;
    background-image: linear-gradient(
      269.65deg,
      ${({ theme }) => theme.colors.gradient} 0.65%,
      ${({ theme }) => theme.colors.gradient2} 111.47%
    );
    background-position: center;

    ::before {
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      background-image: url(${homeBgLg});
      background-position: top left;
      background-size: auto calc(100% + 1px);
      background-repeat: no-repeat;
    }

    h1 {
      position: relative;
      z-index: 5;
      width: 800px;
      font-size: 70px;
    }
  }

  @media screen and ${({ theme }) => theme.devices.xxl} {
    margin: 0;
  }
`;

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.79;
  text-align: center;

  @media screen and ${({ theme }) => theme.devices.sm} {
    flex-direction: column-reverse;
    font-size: 30px;
    line-height: 1.67;
  }
`;

const Button = styled.a`
  ${ButtonStyle}

  @media screen and ${({ theme }) => theme.devices.sm} {
    padding: 20px 110px;
    font-size: 16px;
    line-height: 0.75;
  }
`;

const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  ${Button} {
    margin-bottom: 20px;
  }

  @media screen and ${({ theme }) => theme.devices.sm} {
    margin-top: 80px;
    margin-bottom: 60px;
  }
`;

export const Home = () => {
  const wrapperTitleEl = useRef(null);

  useEffect(() => {
    if (!IS_TABLET) {
      const handleGradient = (e) => {
        const mouseXpercentageM = Math.round(
          (e.pageX / window.innerWidth) * 100,
        );
        const mouseYpercentageM = Math.round(
          (e.pageY / window.innerHeight) * 100,
        );

        wrapperTitleEl.current.style.backgroundImage = `radial-gradient(circle farthest-side at ${mouseXpercentageM}% ${mouseYpercentageM}%, ${GRADIENT_COLOR_2}, ${GRADIENT_COLOR})`;
      };

      addCursorListener(wrapperTitleEl, handleGradient);

      return () => {
        removeCursorListener(wrapperTitleEl, handleGradient);
      };
    }
  }, []);

  return (
    <HomeWrapper
      styles={{
        xs: { padding: '10px 0 36px' },
        sm: { padding: '60px 0 115px' },
      }}
      id={HOME_ID}
      themeType="dark"
    >
      <HomeContainer size="xl" styles={{ xxl: { padding: '0 40px' } }}>
        <TitleContent ref={wrapperTitleEl}>
          <h1>{SECTION_TITLE}</h1>
        </TitleContent>
      </HomeContainer>
      <HomeContainer>
        <HomeContent>
          <p>{SECTION_DESCRIPTION}</p>
          <ContactUs>
            <Button href={CORPORATE_SITE_LINK} target="_blank" rel="noreferrer">
              Сorporate Website
            </Button>
          </ContactUs>
        </HomeContent>
      </HomeContainer>
    </HomeWrapper>
  );
};

export default Home;
