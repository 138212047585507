import React from 'react';

export const SECTION_TITLE = 'SoftTeco In Numbers';

export const DATA = [
  {
    number: '220+',
    title: 'Successful projects',
    description: (
      <>
        SoftTeco has successfully completed over 220 projects across various
        domains, including{' '}
        <b>healthcare, education, e-commerce, and business intelligence.</b>
      </>
    ),
  },
  {
    number: '14+',
    title: 'Years in operation',
    description: (
      <>
        Since 2008, our company has been delivering robust and{' '}
        <b>high-performing software solutions</b> and remains among the leading
        software providers according to trusted sources.
      </>
    ),
  },
  {
    number: '470+',
    title: 'In-house experts',
    description: (
      <>
        SoftTeco unites over 250 talented IT professionals, including{' '}
        <b>
          software engineers, QA specialists, business analysts, and designers.
        </b>
      </>
    ),
  },
  {
    number: '180+',
    title: 'Clients worldwide',
    description: (
      <>
        We partner with clients from{' '}
        <b>North America, Canada, the UK, Europe, and other countries</b> and
        deliver software solutions for businesses of any size and complexity.
      </>
    ),
  },
];
