import { SIZE } from 'constants/system';

const gradientColor = '#266bf2';
const gradientColor2 = '#cd1c91';
const lighterColorRGB = '255, 255, 255';
const shadowColorRGB = '60, 61, 65';

export const SHADOW_COLOR_RGB = shadowColorRGB;
export const GRADIENT_COLOR = gradientColor;
export const GRADIENT_COLOR_2 = gradientColor2;
export const LIGHTER_COLOR_RGB = lighterColorRGB;

export const THEME = {
  colors: {
    primary: '#276CF2',
    primaryLight: '#438afe',
    gradient: gradientColor,
    gradient2: gradientColor2,
    lighter: '#ffffff',
    light: '#F2F2F2',
    dark: '#000000',
    secondary: '#191919',
    secondaryLight: '#7c7c7c',
    error: '#FF2E3B',
    errorLight: '#f9848b',
    success: '#389820',
    lighterRGB: lighterColorRGB,
    darkRGB: '0, 0, 0',
    disabledRGB: '82, 82, 82',
    shadowRGB: shadowColorRGB,
  },
  fonts: {
    main: 'Inter, sans-serif',
  },
  devices: {
    sm: `(min-width: ${SIZE.sm})`,
    lg: `(min-width: ${SIZE.lg})`,
    xl: `(min-width: ${SIZE.xl})`,
    xxl: `(min-width: ${SIZE.xxl})`,
  },
};

export const LIGHTER_THEME = 'lighter';
export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
