import React from 'react';
import styled from 'styled-components';

import withActions from 'hoc/withActions';

import Field from './Field';

const Element = styled.input`
  ${Field}
`;

export const Input = (props) => <Element type='text' {...props} />;

export default React.memo(withActions(Input));
