import React from 'react';
import styled from 'styled-components';

import footerBg from 'images/footer-bg.svg';

import { CONTACT_US_ID } from 'constants/system';

import { Wrapper as ContactsWrapper } from 'containers/Wrapper';
import { Container as ContactsContainer } from 'containers/Container';

import { SECTION_TITLE } from './constants';

import Info from './Info';
import Form from './Form';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.4;

  @media screen and ${({ theme }) => theme.devices.sm} {
    flex-direction: row;
  }
`;

export const Contacts = () => (
  <ContactsWrapper
    id={CONTACT_US_ID}
    styles={{
      xs: {
        paddingBottom: 47,
        backgroundImage: `url(${footerBg})`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom -11px center',
        backgroundSize: 'auto 46px',
      },
      sm: {
        paddingBottom: 140,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom -48px center',
        backgroundSize: 'auto 136px',
      },
    }}
    themeType="dark"
  >
    <ContactsContainer
      title={SECTION_TITLE}
      styles={{ lg: { maxWidth: '1140px' } }}
    >
      <Content>
        <Form />
        <Info />
      </Content>
    </ContactsContainer>
  </ContactsWrapper>
);

export default Contacts;
