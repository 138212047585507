import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import File from './File';

const FilesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-bottom: 25px;
`;

export const Files = ({ onChange, files, disabled }) => {
  const removeFile = useCallback(
    (id) => {
      const updatedFiles = files.filter((file) => file.id !== id);

      onChange(updatedFiles);
    },
    [onChange, files],
  );

  return (
    <FilesWrapper>
      {files.map((file) => (
        <File
          key={file.id}
          {...file}
          onRemove={removeFile}
          disabled={disabled}
        />
      ))}
    </FilesWrapper>
  );
};

Files.propTypes = {
  onChange: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(Files);
