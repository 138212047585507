export const EMAIL_FIELD = 'email';
export const PHONE_FIELD = 'phone';

export const INITIAL_FIELDS_STATE = {
  email: '',
  phone: '',
};

export const SESSION_STORAGE_ID = 'modalContactForm';

export const ERROR_MESSAGE = 'Something was wrong';
export const WARNING_MESSAGE = 'Please input your E-mail or phone number';
export const SUCCESS_MESSAGE = 'Thank you!';

export const TIME_TO_SHOW_MESSAGES = 3000; // 3sec
export const TIME_TO_SHOW_MODAL_FORM = 240000; // 3min
